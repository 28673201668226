import React from "react"
import Layout from "../../../components/sermonLayoutSurprise"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout sermonSrc="https://www.youtube.com/embed/qYs1J6QrWOI">
    <SEO title="Marriage Stuff - Marriage, Money, and Minions" />
  </Layout>
)

export default SermonPost
